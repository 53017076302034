<template>
  <v-card flat color="footer" class="rounded-0">
    <v-container fluid>
      <v-row>
        <v-col>
          <span class="chivo caption grey--text text--darken-3">
            ©Copyright 2022 Tokenfit® - Todos los derechos reservados | 
            <router-link class="azul3--text" to="/">Política de privacidad</router-link> | 
            <router-link class="azul3--text" to="/">Menciones legales</router-link>
          </span>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style>

</style>